@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.mixins.scss";

.LinkList {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--4xs);
	padding: 0;
	margin: var(--spacing--md) 0;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&___row {
		@include mq("md") {
			flex-direction: row;
			align-items: center;
			column-gap: var(--spacing--lg);
		}

		@include mq("lg") {
			column-gap: 5.6rem;
		}
	}

	&___sm {
		font-size: calc(var(--font-size--sm) - 0.1rem);
	}
}

.LinkList_item {
	list-style: none;

	&___active {
		font-weight: var(--font-weight--semibold);
	}
}

.LinkList_link {
	font-size: inherit;
	text-decoration: none;

	@include HoverFocus {
		text-decoration: underline;
	}

	&___underline {
		text-decoration: underline;

		@include HoverFocus {
			text-decoration: none;
		}
	}
}
