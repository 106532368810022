@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.FormfieldTextarea {
	position: relative;
	display: flex;
	flex-flow: column;
	color: var(--color-gradient--80);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
}

.FormfieldTextarea_input {
	@include input;
	background-color: var(--color-gradient--5);
	width: 100%;
	border: var(--border--sm) solid transparent;
	border-radius: var(--corner-size--xs);
	padding: var(--spacing--xs);
	line-height: var(--line-height--md);
	margin: var(--spacing--2xs) 0;
	height: 16rem;
	resize: none;

	.isDisabled & {
		cursor: not-allowed;
	}

	&::placeholder {
		color: currentColor;
	}
}

.FormfieldTextarea_input:not([disabled]):hover,
.FormfieldTextarea_label:hover ~ .FormfieldTextarea_input:not([disabled]),
.FormfieldTextarea_input:focus {
	border-color: var(--color-action--1);
}
