.Container {
	margin-left: auto;
	margin-right: auto;
	padding-left: var(--spacing--base);
	padding-right: var(--spacing--base);

	&___width {
		&_sm {
			max-width: calc(var(--content-width--sm) + 2 * var(--spacing--base));
		}

		&_md {
			max-width: calc(var(--content-width--md) + 2 * var(--spacing--base));
		}

		&_lg,
		&_std {
			max-width: calc(var(--content-width--base) + 2 * var(--spacing--base));
		}

		&_xl {
			max-width: calc(var(--content-width--xl) + 2 * var(--spacing--base));
		}

		&_2xl {
			max-width: calc(var(--content-width--2xl) + 2 * var(--spacing--base));
		}

		&_fluid {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&___spacingBottom {
		&_sm {
			margin-bottom: var(--spacing--3xl);
		}

		&_md {
			margin-bottom: var(--spacing--5xl);
		}

		&_lg {
			margin-bottom: var(--spacing--6xl);
		}
	}

	&___noPadding {
		padding-left: 0;
		padding-right: 0;
	}
}
