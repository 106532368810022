@import "ui/styles/2-tools/tools.typography-mixins.scss";

.SliderNewsItem {
	display: block;
	color: currentColor;
	text-decoration: none;

	&:focus {
		.SliderNewsItem_heading {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				top: calc(var(--border--sm) * -1);
				left: 0;
				right: 0;
				border-top: var(--border--2xl) solid var(--color-focus-outline);
			}
		}
	}

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;

		.SliderNewsItem_heading {
			text-decoration: underline;
		}
	}
}

.SliderNewsItem_heading {
	margin-top: var(--spacing--3xs);
	padding-top: var(--spacing--md);
	border-top: var(--border--sm) solid var(--color-mediumGrey);
}

.SliderNewsItem_text {
	margin-top: var(--spacing--xs);
	overflow: hidden;
	height: 100%;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
}
