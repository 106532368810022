.SubNavigation {
	font-size: var(--font-size--md);
	background-color: var(--color-dark--1);
	color: var(--color-white);

	&___light {
		background-color: var(--color-white);
		color: var(--color-black);
	}
}

.SubNavigation_wrapper {
	transition-property: transform;
	transition-timing-function: ease;
}
