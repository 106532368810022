@import "ui/styles/2-tools/tools.media-query.scss";

.Table {
	position: relative;
	margin-bottom: var(--spacing--2xl);
	transition: mask 0.3s ease-in-out;
	overflow-x: auto;

	@include mq("md") {
		margin-bottom: var(--spacing--3xl);
	}

	&___hasOverflowContent {
		mask: linear-gradient(
			to left,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 1) 12rem,
			rgba(255, 255, 255, 1) 100%
		);

		@include mq("md") {
			mask: linear-gradient(
				to left,
				rgba(255, 255, 255, 0),
				rgba(255, 255, 255, 1) 20rem,
				rgba(255, 255, 255, 1) 100%
			);
		}
	}

	&_table {
		width: 100%;
		border-collapse: collapse;
	}

	&_heading {
		margin-bottom: var(--spacing--sm);

		@include mq("md") {
			margin-bottom: var(--spacing--md);
		}
	}

	&_tableHead {
		th {
			font-weight: var(--font-weight--bold);
		}
	}
}
