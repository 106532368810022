@import "ui/styles/2-tools/tools.media-query.scss";

.Slider_header {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--md);
	margin-bottom: var(--spacing--xl);

	@include mq("md") {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: var(--spacing--2xl);
	}
}

.Slider_heading {
	@include mq("md") {
		flex: 1 0 60%;
	}
}

.Slider_buttons {
	flex: 0 0 auto;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: var(--spacing--xs);

	@include mq("sm") {
		flex-wrap: nowrap;
	}

	&___mobile {
		margin-top: var(--spacing--xl);

		@include mq("md") {
			display: none;
		}
	}

	&___desktop {
		display: none;

		@include mq("md") {
			display: flex;
		}
	}
}

.Slider_button {
	flex: 0 0 auto;
}

.Slider_slides {
	position: relative;
	display: flex;
	flex-wrap: wrap;

	column-gap: var(--spacing--2xs);
	row-gap: var(--spacing--lg);

	@include mq("md") {
		flex-wrap: nowrap;
		align-items: center;
		column-gap: var(--spacing--lg);
	}
}

.Slider .Slider_swiper // Extra specificity to override Swiper default styles
{
	width: 100%;

	@include mq("md") {
		padding-bottom: var(--spacing--3xl);
	}

	:global(.swiper-pagination-bullets) {
		display: none;
		bottom: 0;

		@include mq("md") {
			display: block;
		}
	}

	:global(.swiper-pagination-bullet) {
		opacity: 1;
		background-color: var(--color-light--1Grey);
	}

	:global(.swiper-pagination-bullet-active) {
		background-color: var(--color-action--1);
	}
}

.Slider_slide {
	height: auto;
	max-width: 100%;

	&:global(.swiper-slide-active),
	&:global(.swiper-slide-next) > *,
	&:global(.swiper-slide-prev) > * {
		max-width: 75%;

		@include mq("md") {
			max-width: none;
		}
	}

	&:global(.swiper-slide-next) {
		opacity: 0.5;

		@include mq("md") {
			opacity: 1;
		}
	}

	&:global(.swiper-slide-next) > * {
		margin-right: auto;

		@include mq("md") {
			margin-right: 0;
		}
	}

	&:global(.swiper-slide-prev) > * {
		margin-left: auto;

		@include mq("md") {
			margin-left: 0;
		}
	}

	&___fullWidth {
		&:global(.swiper-slide-active),
		&:global(.swiper-slide-next) > *,
		&:global(.swiper-slide-prev) > * {
			max-width: 100%;
		}
	}
}

@keyframes maxWidth {
	0% {
		max-width: 75%;
	}

	50% {
		max-width: 75%;
	}

	100% {
		max-width: 100%;
	}
}

$slider-arrow-size: 5.6rem;

.Slider_arrow {
	display: none;
	width: $slider-arrow-size;
	height: $slider-arrow-size;
	background: none;
	border: none;
	border-radius: 50%;
	padding: 0;

	@include mq("md") {
		display: block;
	}

	&:focus {
		outline: var(--border--lg) solid var(--color-focus-outline);
		outline-offset: var(--spacing--3xs);
	}

	svg {
		display: block;

		circle {
			stroke: var(--color-action--1);
			fill: transparent;
		}

		path {
			stroke: var(--color-action--1);
		}
	}

	@include mq("xl") {
		position: absolute;
	}

	&[disabled] {
		cursor: default;

		svg {
			circle,
			path {
				stroke: var(--color-light--1Grey);
			}
		}
	}

	&___prev {
		margin-left: auto;

		@include mq("xl") {
			right: calc(100% + var(--spacing--lg));
		}

		svg {
			transform: rotate(180deg);
		}
	}

	&___next {
		@include mq("md") {
			order: 1;
		}

		@include mq("xl") {
			left: calc(100% + var(--spacing--lg));
		}
	}
}
