@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.Modal {
	z-index: var(--z-index--modal);
	position: fixed;
	width: 100vw;
	max-width: 100%;
	height: 100vh;
	align-items: center;
	justify-content: center;
	visibility: visible;
	display: flex;
	top: 0;
	left: 0;
	opacity: 0;
	transition-delay: unset;
	padding: var(--spacing--md);
	transition: opacity var(--trans-time--standard) ease;
	pointer-events: none;

	&___entering {
		animation: modalFadeIn var(--trans-time--shorter) ease forwards;
	}

	&___entering,
	&___entered {
		pointer-events: unset;
		opacity: 1;
	}

	&___exiting,
	&___exited {
		pointer-events: none;
		opacity: 0;
	}
}

@keyframes modalFadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.Modal_overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--shadow-color--mild);
	opacity: 1;

	@include transition {
		transition: var(--trans-time--standard) opacity ease;
	}
}

.Modal_close {
	// Browser reset
	padding: 0;
	background-color: transparent;
	cursor: pointer;

	position: absolute;
	top: var(--spacing--xs);
	right: var(--spacing--xs);
	display: flex;
	align-items: center;
	justify-content: center;
	width: var(--icon-size--xl);
	height: var(--icon-size--xl);
	border: var(--border--sm) solid var(--color-action--1);
	border-radius: 50%;

	@include mq("md") {
		top: var(--spacing--sm);
		right: var(--spacing--sm);
	}

	@include mq("lg") {
		top: var(--spacing--md);
		right: var(--spacing--md);
	}

	&:focus-visible {
		border: var(--border--sm) solid var(--color-action--1);
		outline: var(--border--md) solid var(--color-focus-outline);
		outline-offset: 0.5rem;
	}
}

svg.Modal_closeIcon {
	stroke: var(--color-action--1);
}

.Modal_content {
	overflow-y: auto;
	width: 100%;
	max-height: 100%;
	max-width: 107rem;
	padding: var(--spacing--2xl) var(--spacing--sm) var(--spacing--lg);
	background-color: var(--color-white);
	transform: scale(1);
	z-index: 1;
	opacity: 1;

	@include transition {
		transition: var(--trans-time--shorter) transform ease, var(--trans-time--shorter) opacity ease;
	}

	@include mq("md") {
		padding: var(--spacing--3xl) var(--spacing--xl) var(--spacing--2xl);
	}

	@include mq("lg") {
		padding: var(--spacing--4xl) var(--spacing--4xl) var(--spacing--3xl);
	}
}

.Modal_heading {
	margin-bottom: var(--spacing--xs);
}

.Modal_text {
	margin-bottom: var(--spacing--lg);

	@include mq("lg") {
		margin-bottom: var(--spacing--2xl);
	}
}

.Modal_button {
	margin-top: var(--spacing--lg);

	@include mq("md") {
		margin-top: var(--spacing--2xl);
	}

	@include mq("lg") {
		margin-top: var(--spacing--3xl);
	}
}
