@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.mixins.scss";

.HeroShortcuts {
	background-color: var(--color-light--2);
	padding: var(--spacing--lg) var(--spacing--sm);
	display: flex;
	flex-direction: column;
	gap: var(--spacing--md);

	@include mq("md") {
		padding: var(--spacing--3xl) var(--spacing--5xl);
		gap: var(--spacing--xl);
	}

	&___dark {
		background-color: var(--color-light--1);
	}

	&_list {
		list-style-type: none;
		display: flex;
		gap: var(--spacing--3xs);
		flex-wrap: wrap;

		@include mq("md") {
			gap: var(--spacing--2xs);
		}
	}

	&_link {
		@include HoverFocus {
			background-color: var(--color-action--1);
			border-color: var(--color-action--1);
			outline-offset: 0.5rem; // Due to gap-size we need to decrease the outline-offset otherwise it will be overlapped by siblings
		}
	}
}
