@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";

@mixin backgroundGradient($height, $color1: var(--color-dark--1), $color2: rgba(0, 0, 0, 0)) {
	background: linear-gradient(to bottom, $color1 $height, $color2 0);
}

.HeroContentPage {
	color: var(--color-white);

	&___theme-light {
		color: var(--color-black);

		.HeroContentPage_top {
			background-color: var(--hero-bg-light);
		}
	}

	&___picture {
		padding-bottom: var(--spacing--xl);
	}

	&_top {
		background-color: var(--color-dark--1);
		padding-top: var(--spacing--3xl);
		padding-bottom: var(--spacing--4xl);

		@include mq("md") {
			padding-top: var(--spacing--5xl);
			padding-bottom: var(--spacing--6xl);
		}

		&___picture {
			padding-bottom: var(--spacing--xl);
			@include mq("md") {
				padding-bottom: var(--spacing--4xl);
			}
		}
	}

	&_textbox {
		position: relative;
		width: clamp(54rem, 80%, 80rem);
		max-width: 100%;
	}

	&_gradient {
		@include backgroundGradient(10vh, var(--hero-bg-dark));

		@include mq("sm") {
			@include backgroundGradient(15vh, var(--hero-bg-dark));
		}

		@include mq("md") {
			@include backgroundGradient(20vh, var(--hero-bg-dark));
		}
	}

	&___theme-light .HeroContentPage_gradient {
		@include backgroundGradient(10vh, var(--hero-bg-light));

		@include mq("sm") {
			@include backgroundGradient(15vh, var(--hero-bg-light));
		}

		@include mq("md") {
			@include backgroundGradient(20vh, var(--hero-bg-light));
		}
	}

	&_picture {
		@include mq("md") {
			@include aspect-ratio(16, 9);
		}
	}
}

p.HeroContentPage_lead {
	margin-top: var(--spacing--xs);
	margin-bottom: var(--spacing--2xs);
}
