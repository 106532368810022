@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/_tools.action";
@import "ui/styles/2-tools/_tools.typography-mixins";

.SubNavigationItem {
	width: 100%;
	position: absolute;

	&___isMobile {
		transition: none;
	}
}

.SubNavigationItem_item {
	position: relative;
	display: flex;
	justify-content: space-between;
	min-height: var(--sizes--sub-nav-item);
	transition: background-color var(--trans-time--standard) ease;

	&:hover,
	&:focus {
		background-color: var(--color-dark--2);
		color: var(--color-white);

		+ .SubNavigationItem_item .SubNavigationItem_action:before {
			border-color: transparent;
		}
	}

	.SubNavigationItem_action:before,
	&:last-child .SubNavigationItem_action:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: var(--spacing--sm);
		right: var(--spacing--sm);
		border-top: var(--border--sm) solid var(--color-dark--2);
	}

	&:last-child .SubNavigationItem_action:after {
		top: auto;
		bottom: 0;
	}

	&:first-child .SubNavigationItem_action:before,
	&:last-child .SubNavigationItem_action:after {
		@include mq("lg") {
			content: none;
		}
	}
}

.SubNavigationItem_action {
	&:hover,
	&:focus {
		background-color: var(--color-dark--2);
	}
}

.SubNavigationItem_text {
	@include action;
	width: 100%;
	color: currentColor;
	text-align: left;
	padding: 0 var(--spacing--sm);
	margin: var(--spacing--sm) 0;
	line-height: var(--line-height--2xl);
	text-decoration: none;

	// Constrict to max 2 lines of text
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	text-overflow: ellipsis;
}

.SubNavigationItem_link {
	width: 100%;
	color: currentColor;
	text-decoration: none;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		display: block;
		width: 100%;
	}
}

.SubNavigationItem_topLink {
	position: relative;

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		border-left: 1rem solid var(--color-dark--2);
	}
}

.SubNavigationItem_prev,
.SubNavigationItem_next {
	background-color: transparent;
	border: 0;
	padding: 0;

	display: flex;
	align-items: center;
	width: 100%;
}

.SubNavigationItem_next {
	justify-content: space-between;
}

.SubNavigationItem_prev {
	.SubNavigationItem_text {
		padding-top: 0.2rem;
		padding-left: 0;
		margin: 0;
		line-height: 1;
	}
}

.SubNavigationItem_prevIcon,
.SubNavigationItem_nextIcon {
	width: 0.8rem;
	height: 100%;
	margin: 0 var(--spacing--sm);

	svg {
		flex: 0 0 auto;
		width: var(--icon-size--sm);
		fill: none;
		height: auto;

		path {
			stroke: var(--color-dark--4);
			transition: stroke var(--trans-time--standard) ease;
		}
	}
}

.SubNavigationItem_prevIcon {
	margin-right: var(--spacing--xs);

	svg {
		transform: rotate(180deg);
	}
}

.SubNavigationItem_nextIcon {
	margin-left: 0;
}
