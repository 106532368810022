@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.HeroFocus {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--spacing--sm);
	padding: var(--spacing--lg) var(--spacing--sm);
	margin-bottom: var(--spacing--2xl);

	@include mq("sm") {
		flex-direction: unset;
		gap: var(--spacing--md);
		padding: var(--spacing--xl) var(--spacing--2xl);
	}

	@include mq("md") {
		margin-bottom: var(--spacing--5xl);
	}

	&___light {
		background-color: var(--color-light--2);
		color: var(--color-black);
	}

	&___dark {
		background-color: var(--color-action--1);
		color: var(--color-white);
	}

	&_textbox {
		flex-grow: 1;
		gap: var(--spacing--xs);
		display: flex;
		flex-direction: column;
		align-items: center;

		@include mq("sm") {
			align-items: unset;
		}
	}

	&_kicker,
	&_description {
		margin: 0;
		text-align: center;
		@include mq("sm") {
			text-align: unset;
		}
	}

	&_description {
		@include heading--xs;
		line-height: var(--line-height--2xl);
	}

	&_link {
		.HeroFocus___dark & {
			color: var(--color-white);
		}
	}

	&_picture {
		@include circle(13rem);
		flex-shrink: 0;
	}
}

p.HeroFocus_lead {
	margin-top: var(--spacing--xs);
	margin-bottom: var(--spacing--2xs);
}
