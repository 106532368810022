@import "ui/styles/2-tools/tools.media-query.scss";

.NavigationCards {
	&___bg {
		padding: var(--spacing--4xl) 0;

		&-white {
			background-color: var(--color-white);
		}

		&-light {
			background-color: var(--color-light--2);
		}

		&-dark {
			background-color: var(--color-light--1);
		}
	}
}

.NavigationCards_heading {
	margin-bottom: var(--spacing--xl);

	@include mq("lg") {
		margin-bottom: var(--spacing--2xl);
	}
}

.NavigationCards_cards {
	display: grid;
	grid-template-columns: 1fr;
	gap: var(--spacing--xs);

	@include mq("md") {
		grid-template-columns: repeat(2, 1fr);
	}

	@include mq("lg") {
		grid-template-columns: repeat(3, 1fr);
		gap: var(--spacing--md);
	}

	&___layout-2-3 {
		@include mq("lg") {
			display: flex;
			flex-wrap: wrap;
		}

		.NavigationCards_item {
			flex: 0 1 calc(100% / 3 - var(--spacing--md) / 3 * 2);

			&:nth-child(-n + 2), // Select the first two cards
			&:nth-child(5n + 6), // Select the 6th, 11th, 16th, etc. card
			&:nth-child(5n + 6) + .NavigationCards_item // Select the card after the 6th, 11th, 16th, etc. card
			{
				@include mq("lg") {
					flex-basis: calc(100% / 2 - var(--spacing--md) / 2);
				}
			}
		}
	}

	&___layout-4 {
		@include mq("lg") {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

.NavigationCards_item {
	list-style: none;
	padding: 0;
	margin: 0;

	display: flex;
	flex-direction: column;
}

.NavigationCards_card {
	height: 100%;
}
