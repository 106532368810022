@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";

.FormfieldCheckbox {
	position: relative;
	display: flex;
	flex-flow: column;
	color: var(--color-darkGrey);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}

	.FormfieldCheckbox_input {
		@include visually-hidden;

		// We only want the blue focusBoxShadow shown when tabbing through the input. Not when using mouse
		&:focus-visible {
			& + .FormfieldCheckbox_label {
				@include focusBoxShadow("", true);
			}
		}
	}
}

.FormfieldCheckbox_label {
	margin-bottom: unset;
	font-weight: unset;

	.isDisabled & {
		cursor: not-allowed;
	}

	.FormfieldCheckbox___multiline & {
		align-items: flex-start;
	}

	&:before {
		content: "";
		display: block;
		width: var(--sizes-checkbox-height);
		height: var(--sizes-checkbox-height);
		background-color: var(--color-white);
		margin-right: var(--spacing--3xs);
		flex-shrink: 0;
		border: var(--border--sm) solid currentColor;

		.FormfieldCheckbox_input:checked + & {
			background-color: var(--color-action--1);
			border-color: var(--color-action--1);
		}

		.hasError & {
			border-color: var(--color-error);
		}
	}

	&:hover,
	&:focus {
		&::before {
			border-width: var(--border--md);
		}
	}

	&:after {
		content: "";
		display: block;
		width: calc(var(--sizes-checkbox-height) / 3.2);
		height: calc(var(--sizes-checkbox-height) / 2);
		transform: rotate(45deg) translate(50%, -50%);
		border: solid var(--color-white);
		border-width: 0 var(--border--md) var(--border--md) 0;
		position: absolute;
		left: calc(var(--spacing--xs) / 10);
		top: calc(var(--spacing--xs) / 3);
	}
}

.FormfieldCheckbox_input {
	@include visually-hidden;
}
