@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.action";

.RichText {
	h1 {
		@include heading--lg;
		margin-top: var(--spacing--xl);
		margin-bottom: var(--spacing--lg);
	}

	h2 {
		@include heading--md;
		margin-top: var(--spacing--lg);
		margin-bottom: var(--spacing--xs);
	}

	h3 {
		@include heading--sm;
		margin-top: var(--spacing--md);
		margin-bottom: var(--spacing--3xs);
	}

	h4 {
		@include heading--xs;
		margin-top: var(--spacing--sm);
		margin-bottom: var(--spacing--3xs);
	}

	h5 {
		@include heading--2xs;
		margin-top: var(--spacing--xs);
		margin-bottom: var(--spacing--3xs);
	}

	h6 {
		@include heading--3xs;
		margin-top: var(--spacing--3xs);
		margin-bottom: var(--spacing--3xs);
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	p {
		@include paragraph;
	}

	ul,
	ol {
		margin: var(--spacing--base) 0;
		padding-left: 1em;
		line-height: var(--line-height--2xl);
		@include paragraph;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		@include action;
	}

	img {
		width: 100%;
		height: auto;
	}
}
