@import "ui/styles/2-tools/tools.media-query.scss";

.SearchFieldSimple {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--xs) var(--spacing--xs);

	@include mq("sm") {
		flex-direction: row;
	}

	&_input {
		flex: 1 1 auto;
	}

	.SearchFieldSimple_button  // Extra specificity is needed to override Button styles
	{
		flex: 0 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: var(--spacing--3xs);
		height: var(--sizes-input-height);
		padding: 0 var(--spacing--md);
	}

	&_searchIcon {
		svg {
			circle,
			path {
				stroke: currentColor;
			}
			circle {
				fill: transparent;
			}
		}
	}
}
