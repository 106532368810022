@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.action.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.SearchFilter {
	display: flex;
	flex-direction: column;
	padding: var(--spacing--lg) var(--spacing--sm);
	background-color: var(--color-light--2);

	@include mq("md") {
		gap: var(--spacing--xs);
		padding: var(--spacing--3xl) var(--spacing--5xl);
	}

	&_form {
		display: flex;
		flex-direction: column;
		gap: var(--spacing--xs);

		@include mq("md", max) {
			max-height: 0;
			opacity: 0;
			padding-top: 0;
			visibility: hidden;

			@include transition {
				transition-property: max-height, opacity, padding-top, visibility;
				transition-duration: var(--trans-time--long);
				transition-timing-function: ease-in-out;
			}
		}

		&___open {
			@include mq("md", max) {
				max-height: 1000rem;
				opacity: 1;
				padding-top: var(--spacing--lg);
				visibility: visible;
			}
		}
	}

	&_heading {
		display: inline-flex;
	}

	&_toggle {
		font-family: inherit;
		font-weight: inherit;
		line-height: inherit;
		background-color: transparent;
		border: none;
		padding: unset;
		flex-grow: 1;
		text-align: left;
		position: relative;
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
		gap: var(--spacing--xs);
		border-radius: 1rem;
		color: var(--color-black);

		&:focus-visible {
			@include focusBoxShadow("", true);
		}

		&Icon {
			width: 1.6rem;
			height: 1.6rem;
			position: relative;
			flex-shrink: 0;

			&___vertical,
			&___horizontal {
				border: solid var(--color-action--1);
				display: inline-block;
				position: absolute;
			}

			&___vertical {
				border-width: 0 0.2rem 0 0;
				width: 0.2rem;
				height: 100%;
				left: calc(50% - 0.1rem);
			}

			&___horizontal {
				border-width: 0 0 0.2rem 0;
				width: 100%;
				height: 0.2rem;
				top: calc(50% - 0.1rem);
			}
		}
	}

	&_filters {
		display: flex;
		flex-direction: column;
		gap: var(--spacing--xs);
		flex-wrap: wrap;
		overflow: hidden;

		@include mq("md") {
			flex-direction: row;
			overflow: visible;
			gap: var(--spacing--2xs) var(--spacing--sm);
			padding-top: var(--spacing--xs);
		}
	}

	&_comboboxContainer {
		@include mq("md") {
			position: relative;
			flex: 1 33%;
			min-width: 22rem;
		}

		@include mq("lg") {
			flex-basis: 18%;
		}
	}

	&_btnWrapper {
		display: flex;
		gap: var(--spacing--md);
		flex-shrink: 0;
		flex-wrap: wrap;
		flex-grow: 1;

		@include mq("md") {
			flex-direction: row-reverse;
			flex-wrap: nowrap;
		}
	}

	&_submitBtn {
		flex: 0 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: var(--spacing--3xs);
		height: var(--sizes-input-height);
		padding: 0 var(--spacing--md);
	}

	&_clearBtn {
		flex-shrink: 0;
	}

	&_searchIcon {
		svg {
			circle,
			path {
				stroke: currentColor;
			}
			circle {
				fill: transparent;
			}
		}
	}
}
