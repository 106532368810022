@import "ui/styles/2-tools/tools.media-query.scss";

.DesktopNavigationItem {
	position: relative;
}

.DesktopNavigationItem_link {
	display: flex;
	align-items: center;
	gap: 1rem;
	color: currentColor;
	position: relative;
	padding: 0 var(--spacing--xs) var(--sizes--header-padding-bottom);
	font-size: 1.7rem;
	white-space: nowrap;
	text-decoration: none;
	cursor: pointer;

	@include mq("xl") {
		padding-left: var(--spacing--base);
		padding-right: var(--spacing--base);
	}

	.isActive &:after,
	&:hover:after,
	&:focus:after {
		content: "";
		display: block;
		position: absolute;
		border-bottom: var(--border--2xl) solid var(--color-dark--4);
		left: var(--spacing--xs);
		right: var(--spacing--xs);
		bottom: 0;

		@include mq("xl") {
			left: var(--spacing--base);
			right: var(--spacing--base);
		}
	}
}

.DesktopNavigationItem:last-child .DesktopNavigationItem_link {
	padding-right: 0;

	&:after {
		right: 0;
	}
}

.DesktopNavigationItem_icon {
	width: 1.2rem;
	height: 0.8rem;

	path {
		stroke: var(--color-dark--4);
	}
}

.DesktopNavigationItem_subNavContainer {
	position: absolute;
	top: 100%;
	left: var(--spacing--base);
	width: var(--sizes--nav-container-width);
	padding: var(--spacing--3xs) 0;
	opacity: 0;
	transition: opacity var(--trans-time--long) ease, transform var(--trans-time--long) ease;
	pointer-events: none;
	z-index: 11;

	.DesktopNavigationItem.isActive & {
		width: var(--sizes--nav-container-width);
	}

	&.enterActive,
	&.enterDone,
	&.exitActive {
		pointer-events: inherit;
	}

	&.enterActive,
	&.enterDone {
		opacity: 1;
	}
}

.DesktopNavigationItem:nth-last-child(-n + 3) {
	.DesktopNavigationItem_subNavContainer {
		left: 50%;
		transform: translateX(-50%);
	}
}

.DesktopNavigationItem:last-child {
	.DesktopNavigationItem_subNavContainer {
		left: auto;
		right: 0;
		transform: none;

		@include mq("2xl") {
			right: calc(2 * var(--spacing--base) * -1);
		}

		@include mq("3xl") {
			right: 50%;
			transform: translateX(50%);
		}
	}
}

.DesktopNavigationItem_subNavContainer_inner {
	max-height: 48.5rem;
	overflow-y: auto;
	box-shadow: 0.4rem 0.4rem 1.8rem 0.1rem rgba(0, 0, 0, 0.2);

	&::-webkit-scrollbar {
		width: 0.5rem;
	}

	&::-webkit-scrollbar-track {
		background: var(--color-dark--1);
	}

	&::-webkit-scrollbar-thumb {
		background: var(--color-dark--3);

		&:hover,
		&:focus {
			background: var(--color-dark--4);
		}
	}
}
