.ArticlePicture {
	width: 100%;

	&_caption {
		color: var(--color-darkGrey);
		padding: var(--spacing--xs) 0;
		border-bottom: 1px solid var(--color-mediumGrey);
	}
}

