@import "ui/styles/2-tools/tools.media-query.scss";

.Hero {
	--hero-gradient-color: var(--hero-gradient-rgba-dark);

	background: var(--color-dark--1);
	color: var(--color-white);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	padding-top: var(--spacing--3xl);
	padding-bottom: calc(var(--spacing--5xl));

	@include mq("md") {
		padding-top: calc(var(--spacing--6xl) + var(--spacing--xl));
		padding-bottom: calc(var(--spacing--6xl) * 2);
	}

	&___theme-light {
		--hero-gradient-color: var(--hero-gradient-rgba-light);

		color: var(--color-black);
		background-color: var(--hero-bg-light);
	}

	&_gradient {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(90deg, var(--hero-gradient-color) 0%, transparent 66%),
			linear-gradient(180deg, var(--hero-gradient-color) 0%, transparent 80%);
	}

	&___theme-light &_heading {
		color: var(--color-action--1);
	}

	&_video {
		position: absolute;
		object-fit: cover;
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		&::-webkit-media-controls {
			display: none !important;
		}

		@media (prefers-reduced-motion) {
			display: none;
		}
	}

	&_textbox {
		position: relative;
		@include mq("md") {
			width: clamp(54rem, 60%, 75rem);
		}
	}

	&_lead {
		margin-top: var(--spacing--xs);
		@include mq("md") {
			width: clamp(54rem, 80%, 60rem);
		}
	}

	&_linkbtn {
		margin-top: var(--spacing--lg);
	}

	&_focus {
		position: relative;
		margin-top: calc(var(--spacing--xl) * -1);

		@include mq("md") {
			margin-top: calc(var(--spacing--4xl) * -1);
		}
	}
}
