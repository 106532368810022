@import "ui/styles/2-tools/tools.media-query.scss";

.BannerWide {
	--image-offset-top: var(--spacing--2xl);

	padding-top: var(--image-offset-top);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0) var(--image-offset-top),
		var(--color-light--3) var(--image-offset-top),
		var(--color-light--3) var(--spacing--2xl)
	);

	@include mq("lg") {
		--image-offset-top: var(--spacing--3xl);
	}

	&___dark {
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) var(--image-offset-top),
			var(--color-action--1) var(--image-offset-top),
			var(--color-action--1) var(--spacing--2xl)
		);

		.BannerWide_content {
			color: var(--color-white);

			.BannerWide_text {
				a {
					color: var(--color-white);
				}
			}
		}

		.LinkButton___primary-negative {
			color: var(--color-action--1);
		}
	}

	&_inner {
		display: flex;
		flex-direction: column;

		@include mq("md") {
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			column-gap: var(--spacing--xl);
		}

		@include mq("lg") {
			column-gap: calc(var(--spacing--3xl) + 1rem);
		}
	}

	&_imageContainer {
		align-self: flex-start;
		width: calc(100% + var(--spacing--base));
		margin-top: calc(var(--image-offset-top) * -1);
		margin-left: calc(
			var(--spacing--base) * -1
		); // Offset image to the left equal to the padding of the container so it hugs the left edge
		aspect-ratio: 1.2 / 1;

		@include mq("md") {
			flex: 0 0 50%;
			width: 50%;
			max-width: 57rem;
			margin-bottom: var(--spacing--3xl);
			margin-left: 0;
			aspect-ratio: 57 / 50;
		}

		@include mq("lg") {
			flex: 0 0 100%;
			width: 100%;
			margin-bottom: calc(var(--spacing--5xl) - 0.5rem);
		}
	}

	&_content {
		flex: 1 1 auto;
		max-width: 52rem;
		padding: var(--spacing--md) 0 var(--spacing--2xl);

		@include mq("md") {
			padding: var(--spacing--xl) 0;
		}

		@include mq("lg") {
			padding: var(--spacing--3xl) 0;
		}
	}

	&_heading {
		margin-bottom: var(--spacing--xs);
	}

	&_text {
		@include mq("md") {
			width: 80%;
		}
	}

	&_buttons {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: var(--spacing--xs) var(--spacing--2xs);
		margin-top: var(--spacing--lg);
	}
}

// Right aligned
.BannerWide___image-right {
	.BannerWide_inner {
		@include mq("md") {
			flex-direction: row-reverse;
			justify-content: flex-end;
		}
	}

	.BannerWide_imageContainer {
		width: calc(100% + var(--spacing--base));
		margin-left: 0;
		margin-right: calc(
			var(--spacing--base) * -1
		); // Offset image to the right equal to the padding of the container so it hugs the right edge

		@include mq("md") {
			width: 50%;
			margin-right: 0;
		}
	}
}
