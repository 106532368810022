@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Heading {
	&___2xs {
		@include heading--2xs;
	}

	&___xs {
		@include heading--xs;
	}

	&___sm {
		@include heading--sm;
	}

	&___md {
		@include heading--md;
	}

	&___lg {
		@include heading--lg;
	}

	&___xl {
		@include heading--xl;
	}

	&___2xl {
		@include heading--2xl;
	}
}
