@import "ui/styles/2-tools/tools.media-query.scss";

.Header {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: var(--sizes--header-height-mobile);
	padding: var(--spacing--xs) 0;
	font-size: var(--font-size--sm);
	color: var(--color-white);
	z-index: var(--z-index--header);
	background-color: var(--color-dark--1);
	will-change: transform, background-color, opacity, margin;

	@include mq("lg") {
		justify-content: flex-end;
		overflow: visible;
		padding: var(--spacing--lg) 0 0;
		height: auto;
		min-height: var(--sizes--header-height-desktop);
		padding-bottom: 0;
	}

	&___light {
		background-color: var(--color-white);
		color: var(--color-black);
	}
}

.Header_inner {
	width: 100%;

	&___border {
		border-bottom: 1px solid var(--color-dark--3);
	}
}

.Header_identity {
	display: flex;
	max-width: 16rem;

	@include mq("lg") {
		max-width: 24rem;
		padding-bottom: var(--sizes--header-padding-bottom);
	}
}

.Header_grid {
	align-items: center;
	justify-content: space-between;

	@include mq("lg") {
		align-items: flex-end;
	}
}

.Header_navigation {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.Header_nav {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
