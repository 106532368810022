.EventMeta {
	padding: var(--spacing--md) var(--spacing--lg);
	font-family: var(--font-secondary);
	font-size: var(--font-size--sm);
	background-color: var(--color-light--3);

	&_date {
		margin: 0;
		font-weight: var(--font-weight--semibold);
	}

	&_info,
	&_link {
		margin-top: var(--spacing--xs);
	}

	&_info {
		p {
			margin: 0;

			&:not(:last-child) {
				margin-bottom: var(--spacing--5xs);
			}
		}
	}
}
