@import "ui/styles/2-tools/tools.typography-mixins.scss";

.AccordionItem_heading {
	border-top: var(--border--sm) solid var(--color-mediumGrey);

	&:last-of-type {
		border-bottom: var(--border--sm) solid var(--color-mediumGrey);
	}

	&___isOpen:last-of-type {
		border-bottom: 0;
	}

	&___isFocus {
		border-top: var(--border--lg) solid var(--color-focus-outline);

		+ .AccordionItem_panel___isOpen {
			border-bottom: var(--border--lg) solid var(--color-focus-outline);

			+ .AccordionItem_heading {
				border-top: 0;
			}
		}
	}
}

$accordionItemTriggerOffset: var(--spacing--xs);

.AccordionItem_trigger {
	position: relative;
	display: flex;
	align-items: center;
	width: calc(100% + #{$accordionItemTriggerOffset});
	border: 0;
	margin: 0;
	margin-left: calc(var(--spacing--xs) * -1);
	padding: var(--spacing--md) var(--spacing--lg) var(--spacing--md)
		calc(var(--spacing--lg) + #{$accordionItemTriggerOffset});
	background: none;
	text-align: left;
	outline: none;

	&:focus {
		outline: var(--border--lg) solid var(--color-focus-outline);
	}

	&___isOpen {
		padding-bottom: var(--spacing--md);
	}
}

.AccordionItem_trigger:focus .AccordionItem_icon,
.AccordionItem_trigger:hover .AccordionItem_icon {
	border-color: var(--color-dark--1);
}

.AccordionItem_trigger[aria-expanded="true"] .AccordionItem_icon:after {
	transform: translateY(-50%) rotate(180deg);
	opacity: 0;
}

.AccordionItem_title {
	font-size: var(--accordion-title-font-size);
	pointer-events: none;
	line-height: var(--line-height--md);
}

.AccordionItem_icon {
	position: absolute;
	top: 50%;
	left: $accordionItemTriggerOffset;
	transform: translateY(-50%);
	width: var(--spacing--xs);
	height: var(--spacing--xs);

	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: var(--border--md);
		background-color: var(--color-dark--1);
		transform: translateY(-50%);
		transition: all var(--trans-time--standard) ease;
	}

	&:after {
		transform: translateY(-50%) rotate(90deg);
	}
}

.AccordionItem_panel {
	@media print {
		height: auto !important; // Using !important to override the inline style set by react-animate-height library
		overflow: visible !important; // Using !important to override the inline style set by react-animate-height library
	}
}

.AccordionItem_panel_inner {
	padding: 0 var(--spacing--lg) var(--spacing--lg);

	@media print {
		display: block !important; // Using !important to override the inline style set by react-animate-height library
	}
}

.AccordionItem_content {
	li {
		@include paragraph;
	}
}
