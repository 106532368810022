@import "ui/styles/2-tools/tools.media-query.scss";

.JobCard {
	background-color: var(--color-light--3);
	padding: var(--spacing--md);
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// margin: var(--spacing--xs) 0;

	@include mq("lg") {
		padding: var(--spacing--lg);
	}

	&_heading {
		padding-bottom: var(--spacing--sm);

		@include mq("lg") {
			padding-bottom: var(--spacing--md);
		}
	}

	&_subheader {
		color: var(--color-darkGrey);
	}

	&_listInfo {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		padding-bottom: var(--spacing--xs);

		&___lastChild {
			border-bottom: none;
			padding-bottom: var(--spacing--xl);
		}

		&_details {
			display: flex;
			flex-direction: column;
			gap: var(--spacing--lg);
			position: relative;
			align-items: flex-start;
			padding-top: var(--spacing--xs);

			@include mq("lg") {
				flex-direction: row;
				justify-content: space-between;
				gap: var(--spacing--sm);
			}

			&:after {
				position: absolute;
				content: "";
				max-width: 25%;
				min-width: 8.5rem;
				width: 100%;
				border-bottom: var(--border--sm) solid var(--color-mediumGrey);
				top: 0;
				left: 0;
			}

			&___lastChild {
				border-bottom: none;
				padding-bottom: var(--spacing--xl);
			}
		}
	}

	&_label {
		@include mq("md") {
			min-width: 30%;
		}
	}

	&_latestDate {
		padding: var(--spacing--lg) 0 var(--spacing--xl);
	}
}
