@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Embed {
	position: relative;
	height: 100%;
	margin-bottom: calc(var(--grid-gutter) * 2);

	&_title {
		margin-bottom: var(--spacing--sm);
	}

	&_media {
		margin-bottom: 0.5em;
	}

	&_mediabox {
		@include aspect-ratio(16, 9);
		> * {
			width: 100% !important;
			height: 100% !important;
		}
	}

	// Additional styling til possible script/
	// stylesheets are loaded
	p,
	span {
		@include paragraph;
	}

	button {
		@include button;
		padding: var(--spacing--xs) var(--spacing--sm);
		border: none;

		&:disabled {
			@include buttonDisabled;
		}
	}

	a {
		@include action;
	}

	li {
		list-style: none;
	}

	form {
		label {
			@include formfield-label;
		}
	}
}
