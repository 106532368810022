/* ------------------------------------ *\
		tools.display-mixins
\* ------------------------------------ */

// Based on BlazeUI
// More info: http://blazeui.com

@mixin hidden-absolute {
	position: absolute;
	left: -99999px;
}

// https://a11yproject.com/posts/how-to-hide-content/
@mixin visually-hidden {
	position: absolute;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	white-space: nowrap; /* added line */
}
