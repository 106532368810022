@import "ui/styles/2-tools/tools.media-query.scss";

.ServiceNavigation {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--lg) var(--spacing--sm);

	&___desktop {
		flex-direction: row;
		align-items: center;
	}
}

.ServiceNavigation_list {
	display: flex;
	align-items: center;
	gap: var(--spacing--sm);

	.ServiceNavigation___desktop & {
		order: 1;
	}
}

.ServiceNavigation_item {
	list-style: none;
}

.ServiceNavigation_link {
	padding: 0.5rem 0;
	font-family: inherit;
	color: currentColor;
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--semibold);
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 0.12em;

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
	}

	&:after {
		content: "";
		display: block;
		width: 0;
		height: 2px;
		background: currentColor;
		transition: width var(--trans-time--standard) ease-in;
	}

	&___isActive,
	&:hover {
		color: currentColor;

		&:after {
			width: 100%;
		}
	}
}

.ServiceNavigation_search {
	display: flex;
	gap: 0.2rem;

	.ServiceNavigation___desktop & {
		order: 2;
	}
}

.ServiceNavigation_searchInput {
	flex: 1 1 auto;
	height: var(--sizes-input-height);
	border: 2px solid var(--color-white);
	border-top-left-radius: 0.8rem;
	border-bottom-left-radius: 0.8rem;
	padding: var(--spacing--2xs) var(--spacing--xs);
	color: var(--color-black);
	font-size: var(--font-size--md);

	&:focus {
		outline: none;
		border-color: var(--color-action--2);
	}
}

.ServiceNavigation_searchButton {
	// Browser resets
	padding: 0;
	margin: 0;
	border: none;

	flex: 0 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top-right-radius: 0.8rem;
	border-bottom-right-radius: 0.8rem;
	width: 5rem;
	height: var(--sizes-input-height);
	padding-left: 0.2rem;
	background-color: var(--color-white);

	.ServiceNavigation___desktop & {
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
	}

	&___light {
		background-color: var(--color-action--1);

		.ServiceNavigation_searchIcon {
			color: var(--color-white);
		}
	}
}

.ServiceNavigation_searchIcon {
	color: var(--color-action--1);
}
