.SkipToMain {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	max-height: 1px;
	height: var(--spacing--xl);
	overflow: hidden;
	margin-top: -1px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--spacing--md);
	color: var(--color-dark--1);
	font-size: var(--font-size--md);
	font-weight: var(--font-weight--semibold);
	line-height: var(--line-height--lg);
	text-align: center;
	text-decoration: none;

	&:focus,
	&:hover {
		max-height: 4rem;
		border: 0.2rem solid var(--color-white);
		background: var(--color-white);
		text-decoration: none;
	}

	&:not(:focus) {
		max-height: 0px;
		margin-top: -1px;
	}
}
