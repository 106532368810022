@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.grid-mixins.scss";

.GridCell {
	@include grid__cell;

	@include grid__cells;

	@include mq("xs") {
		@include grid__responsive-cells(xs);
	}

	@include mq("sm") {
		@include grid__responsive-cells(sm);
	}

	@include mq("md") {
		@include grid__responsive-cells(md);
	}

	@include mq("lg") {
		@include grid__responsive-cells(lg);
	}

	@include mq("xl") {
		@include grid__responsive-cells(xl);
	}

	&___flex {
		display: flex;
	}

	&___top {
		@include grid__cell--top;
	}

	&___center {
		@include grid__cell--center;
	}

	&___bottom {
		@include grid__cell--bottom;
	}

	&___no-gutter {
		@include grid__cell--no-gutter;
	}

	&___width-fixed {
		@include grid__cell--width-fixed;
	}

	&___hidden {
		@include grid__cell--hidden;
	}

	&___visible {
		@include grid__cell--visible;
	}
}
