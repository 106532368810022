.Spinner {
	text-align: center;
	&_element {
		width: var(--icon-size--lg);
		height: var(--icon-size--lg);
		border-top-color: var(--color-dark--1);
		border-left-color: var(--color-dark--1);
		animation: spinner 400ms linear infinite;
		border-bottom-color: transparent;
		border-right-color: transparent;
		border-style: solid;
		border-width: var(--border--md);
		border-radius: 50%;
		box-sizing: border-box;
		display: inline-block;
		vertical-align: middle;

		&___size {
			&-small {
				width: var(--icon-size--md);
				height: var(--icon-size--md);
				border-width: var(--border--md);
			}

			&-large {
				width: var(--icon-size--2xl);
				height: var(--icon-size--2xl);
				border-width: var(--border--2xl);
			}
		}

		&___style {
			&-slow {
				animation: spinner 1s linear infinite;
			}
		}

		&___color {
			&-light {
				border-top-color: var(--color-white--1);
				border-left-color: var(--color-white--1);
			}
		}
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}

.Spinner_screenReader {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
	.Spinner {
		display: none;
	}

	.Spinner_loadingMsg {
		position: relative;
		width: auto;
		height: auto;
		padding: var(--spacing--xs);
		overflow: hidden;
		clip: auto;
		white-space: nowrap;
		border: var(--border--lg) solid var(--color-error);
		color: var(--color-light--1);
		font-size: var(--font-size--lg);
		background: var(--color-error);
	}
}
