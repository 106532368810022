@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";

.FormfieldString {
	position: relative;
	display: flex;
	flex-flow: column;
	color: var(--color-black);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-light--1Grey);
	}
}

.FormfieldString_input {
	@include input;
	-webkit-appearance: none;
	margin: 0;
	width: 100%;

	.isDisabled & {
		cursor: not-allowed;
	}
}

.FormfieldString_label {
	margin-bottom: var(--spacing--3xs);
}

.FormfieldString_input:not([disabled]):hover,
.FormfieldString_label:hover ~ .FormfieldString_input:not([disabled]),
.FormfieldString_input:focus {
	border-width: var(--border--md);
	border-color: var(--color-action--1);
}
