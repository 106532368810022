@import "ui/styles/2-tools/tools.media-query.scss";

.FactBox {
	margin: var(--spacing--xl) 0;
	padding: var(--spacing--lg) var(--spacing--md);
	background-color: var(--color-light--3);

	@include mq("md") {
		margin: var(--spacing--3xl) 0;
		padding: var(--spacing--3xl) calc(var(--spacing--4xl) + 1rem);
	}

	&___dark {
		background-color: var(--color-action--1);
		color: var(--color-white);

		a {
			color: var(--color-white);

			&:focus-visible {
				outline-color: var(--color-white);
			}
		}
	}

	&_heading {
		margin-bottom: var(--spacing--sm);

		@include mq("md") {
			margin-bottom: var(--spacing--md);
		}
	}

	&_image {
		margin-top: var(--spacing--xl);

		@include mq("md") {
			margin-top: var(--spacing--2xl);
		}
	}
}
