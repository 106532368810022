@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.mixins.scss";

.ContactPerson {
	display: grid;
	gap: var(--spacing--3xl) var(--spacing--xl);
	margin-block: 5rem;
}

.ContactPerson_item {
	display: flex;
	gap: 2rem;

	@include mq("sm", max) {
		flex-direction: column;
	}
}

.ContactPerson_profile {
	height: 80px;
	min-width: 80px;
	max-width: 80px;
	border-radius: var(--corner-circle);
}

.ContactPerson_initials {
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	font-weight: 600;
	font-size: var(--font-size--xl);
	color: var(--color-white);
	background-color: var(--color-action--1);
	font-family: var(--font-secondary);
}

.ContactPerson_information {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	margin: 0;
}

.ContactPerson_text {
	margin: 0;
	word-break: break-all;
	font-family: var(--font-secondary);
}
