@import "ui/styles/2-tools/tools.mixins.scss";

.ArrowRightLink {
	display: inline-flex;
	align-items: center;
	text-decoration: none;

	@include HoverFocus {
		text-decoration: underline;
	}

	&_icon {
		margin-left: var(--spacing--2xs);
		width: unset;
		height: unset;

		svg {
			fill: none;
		}
	}
}
