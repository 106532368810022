.Hamburger {
	position: relative;
	width: var(--sizes--hamburger-width);
	height: var(--sizes--hamburger-height);
	cursor: pointer;
}

.Hamburger_line {
	position: absolute;
	display: block;
	height: 1.5px;
	background-color: currentColor;
	border: 0.8px solid currentColor;
	transition: all var(--trans-time--long) ease-in-out;

	&:first-child {
		top: 0;
		width: 100%;
	}

	&:nth-child(2) {
		top: 50%;
		width: 75%;
		transform: translateY(-50%);
	}

	&:last-child {
		bottom: 0;
		width: 50%;
	}
}

.Hamburger___isActive .Hamburger_line {
	&:first-child {
		top: 50%;
		transform: translateY(-50%) rotate(45deg);
	}

	&:nth-child(2) {
		width: 0%;
		border: 0;
		opacity: 0;
	}

	&:last-child {
		bottom: 50%;
		width: 100%;
		transform: translateY(50%) rotate(-45deg);
	}
}
