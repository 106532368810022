@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.action";

.FormfieldSelect {
	position: relative;
	display: flex;
	flex-flow: column;

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
}
.FormfieldSelect_label {
	@include formfield-label;

	margin-bottom: var(--spacing--3xs);
}

.FormfieldSelect_wrapper {
	position: relative;

	&::placeholder {
		color: currentColor;
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		right: var(--spacing--base);
		top: 50%;
		height: 1rem;
		width: 1rem;
		border: solid var(--color-black);
		border-width: 0 0 var(--border--md) var(--border--md);
		background: transparent;
		pointer-events: none;
		transform: translateY(-66.7%) rotate(-45deg);
	}

	.hasError & {
		color: var(--color-error);

		.FormfieldSelect_element {
			border-color: var(--color-error);
		}
	}

	&.isMultiple {
		&:after,
		&:before {
			display: none;
		}

		.FormfieldSelect_element {
			padding-right: var(--spacing--base);
		}
	}
}

.FormfieldSelect_element {
	@include input;
	width: 100%;
	appearance: none;
	cursor: pointer;
	outline: none;
	padding-right: 5rem;
	text-overflow: ellipsis;

	.isDisabled & {
		cursor: not-allowed;
	}

	&::-ms-expand {
		display: none;
	}

	&::placeholder {
		color: currentColor;
	}
}
.FormfieldSelect_label:focus,
.FormfieldSelect_label:hover {
	cursor: auto;
	& ~ .FormfieldSelect_wrapper .FormfieldSelect_element {
		border-color: transparent;
	}
}

.FormfieldSelect_element:focus,
.FormfieldSelect_element:not([disabled]):hover {
	border-color: var(--color-action--1);
}
