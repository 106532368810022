@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.Linkbox_container {
	width: 100%;
}

.Linkbox {
	margin-bottom: var(--spacing--module-bottom);
	padding: var(--spacing--xs) var(--spacing--md);

	@include mq("md") {
		padding: var(--spacing--3xl);
	}

	@include mq("lg") {
		padding: var(--spacing--md);
		margin-bottom: var(--spacing--4xl);
	}
}

.Linkbox_heading {
	padding-bottom: var(--spacing--md);
}

.Linkbox_linksContainer {
	@include mq("md") {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: var(--spacing--lg);
	}
}

.Linkbox_links {
	list-style-type: none;
	border-bottom: 1px solid var(--color-gray);
}

.Linkbox_link {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: var(--spacing--3xs) 0;
	gap: var(--spacing--sm);
	text-decoration: none;
	color: var(--color-action--1);
	@include transition {
		transition: var(--trans-time--long);
	}

	&:hover,
	&:focus {
		transform: translateX(0.4rem);
		@media (prefers-reduced-motion: reduce) {
			transform: unset;
			text-decoration: underline;
		}
	}
}

.Linkbox_icon {
	stroke: var(--color-primary);
	stroke-width: 1.5;
	flex-shrink: 0;
}
