@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.MobileNavigation_toggle {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: var(--spacing--3xs);
	min-height: 4rem;
	min-width: 9.3rem;
	padding: 0 var(--spacing--2xs);

	@include mq("lg") {
		display: none;
	}

	&:hover,
	&:active,
	&:focus {
		border-color: currentColor;
	}

	&___isOpen {
		min-width: 4rem;
		width: 4rem;
		padding: 0;

		.MobileNavigation_toggle_text {
			display: none;
		}
	}
}

.MobileNavigation_wrapper {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	height: calc(100vh - var(--sizes--header-height-mobile));
	z-index: var(--z-index--mobile-nav);
	overflow-y: scroll;
	padding: var(--spacing--3xs) 0 var(--spacing--lg);
	background-color: var(--color-dark--1);

	@include transition {
		transition: all var(--trans-time--standard) ease;
	}

	&.enterDone,
	&.enterActive,
	&.exit {
		transform: translateX(0);
	}

	&,
	&.exitActive {
		transform: translateX(100%);
	}

	&___light {
		background-color: var(--color-white);

		.Button___secondary-negative {
			background: var(--color-dark--1);
		}
	}
}

.MobileNavigation_toggle_text {
	font-size: 1.3rem;
}

.MobileNavigation_serviceNavigation {
	padding: 0 var(--spacing--sm) var(--spacing--sm);

	&___noSearchBox {
		padding-top: var(--spacing--2xs);
	}
}
