@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";

.NavigationCard {
	display: flex;
	flex-direction: column;
	padding: var(--spacing--md);
	transition: all var(--trans-time--standard) var(--ease--dynamic);
	color: currentColor;
	text-decoration: none;

	@include mq("lg") {
		padding: var(--spacing--lg);
	}

	&___linkType-card:not(.NavigationCard___icon) {
		&:hover,
		&:active,
		&:focus {
			color: var(--color-white);
			background-color: var(--color-action--1);
			text-decoration: none;

			a,
			.NavigationCard_link {
				color: var(--color-white);
				text-decoration: none;

				svg path {
					stroke: var(--color-white);
				}
			}
		}
	}

	&___bg-white {
		background-color: var(--color-white);
	}

	&___bg-light {
		background-color: var(--color-light--3);
	}

	&___centered {
		align-items: center;
		text-align: center;
	}

	&___image {
		padding-top: var(--spacing--sm);
		padding-bottom: var(--spacing--sm);

		@include mq("md") {
			padding-top: var(--spacing--md);
			padding-bottom: var(--spacing--md);
		}
	}

	&___icon {
		&:hover,
		&:active,
		&:focus {
			.NavigationCard_link {
				text-decoration: underline;
			}
		}

		.NavigationCard_link {
			text-decoration: none;
		}
	}

	&___icon-small {
		padding-top: var(--spacing--lg);

		.NavigationCard_link {
			padding-top: var(--spacing--sm);

			@include mq("md") {
				padding-top: var(--spacing--md);
			}
		}
	}

	&___icon-large {
		padding-top: var(--spacing--2xl);
		padding-bottom: var(--spacing--xl);

		@include mq("md") {
			padding-top: var(--spacing--3xl);
			padding-bottom: var(--spacing--2xl);
		}

		@include mq("lg") {
			aspect-ratio: 41 / 39;
		}

		.NavigationCard_link {
			@include mq("md") {
				padding-top: var(--spacing--xl);
			}
		}
	}
}

.NavigationCard_image {
	margin-bottom: var(--spacing--sm);
	@include circle(18rem);
}

.NavigationCard_iconContainer {
	position: relative;
	width: 2rem;
	margin-bottom: var(--spacing--sm);

	&:before {
		content: "";
		position: absolute;
		display: block;
		min-width: calc(100% + var(--spacing--lg));
		width: 150%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(0);
		border-radius: 50%;
		transition: all var(--trans-time--standard) var(--ease--dynamic);
		aspect-ratio: 1;
	}

	&___large {
		width: 7.2rem;
		margin-bottom: var(--spacing--lg);

		&:before {
			width: 160%;
		}

		@include mq("lg") {
			width: 7.8rem;
		}
	}
}

.NavigationCard_icon {
	position: relative;
}

.NavigationCard_text {
	margin-top: var(--spacing--2xs);
	margin-bottom: 0;
}

.NavigationCard_link {
	@include action;

	margin-top: auto;
	padding-top: var(--spacing--md);

	@include mq("lg") {
		padding-top: var(--spacing--lg);
	}

	svg path {
		stroke: var(--color-action--1);
		transition: all var(--trans-time--standard) var(--ease--dynamic);
	}
}

.NavigationCard .NavigationCard_linkList {
	// Extra specificity to override the default LinkList styles
	margin-top: var(--spacing--2xs);

	@include mq("md") {
		margin-top: var(--spacing--xs);
	}

	a {
		text-decoration: underline;

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
		}
	}
}
