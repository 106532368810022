@import "ui/styles/2-tools/tools.media-query.scss";

.TeaserSlider {
	&___bg {
		padding: var(--spacing--4xl) 0;

		&-white {
			background-color: var(--color-white);
		}

		&-light {
			background-color: var(--color-light--2);
		}

		&-dark {
			background-color: var(--color-light--1);
		}
	}
}
