@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";

.FormfieldGroup {
	border: none;
	display: flex;
	flex-direction: column;
	gap: var(--spacing--xs);
	padding: var(--spacing--xs);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
}

.FormfieldGroup_label {
	@include formfield-label;

	&___hidden {
		@include visually-hidden;
	}
}
