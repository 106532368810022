@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";

.HeroCanvasPage {
	color: var(--color-white);
	background-color: var(--hero-bg-dark);
	padding-top: var(--spacing--3xl);

	@include mq("md") {
		padding-top: var(--spacing--5xl);
	}

	&___theme-light {
		color: var(--color-black);
		background-color: var(--hero-bg-light);
	}

	&___picture {
		margin-bottom: calc(var(--sizes--hero-canvas-picture-offset) + 2 * var(--spacing--main-top));

		@include mq("md") {
			padding-top: var(--spacing--xl);
		}

		&___disableSpacingBottom {
			margin-bottom: 0;
		}
	}

	&_content_wrapper {
		display: flex;
		flex-direction: column;
		@include mq("md") {
			flex-direction: unset;
			flex-basis: 50%;
		}
	}

	&_textbox {
		position: relative;
		width: clamp(54rem, 80%, 80rem);
		max-width: 100%;
		padding-bottom: var(--spacing--3xl);

		@include mq("md") {
			padding-bottom: var(--spacing--6xl);
		}

		&___picture {
			padding-bottom: var(--spacing--xl);
			flex-shrink: 0;

			@include mq("md") {
				padding-bottom: var(--spacing--5xl);
				padding-top: var(--spacing--2xl);
				flex-basis: 50%;
			}
		}

		&___filter {
			@include mq("md") {
				margin-bottom: var(--spacing--xl);
			}
		}
	}

	&_picture {
		align-self: flex-end;
		max-width: 80%;
		margin-right: calc(-1 * (var(--spacing--base)));
		margin-bottom: calc(var(--sizes--hero-canvas-picture-offset) * -1);

		@include mq("md") {
			margin-left: var(--spacing--2xl);
			max-width: unset;
			margin-right: unset;
		}
	}

	&_wrapper &_filterContainer {
		@include mq("md", max) {
			padding-left: 0;
			padding-right: 0;
			max-width: unset;
		}

		@include mq("md") {
			margin-top: calc(var(--spacing--4xl) * -1);
		}
	}
}

p.HeroCanvasPage_lead {
	margin-top: var(--spacing--xs);
	margin-bottom: var(--spacing--2xs);
}
