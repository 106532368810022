@import "ui/styles/2-tools/tools.media-query.scss";

.CampaignTeaser_heading {
	margin-bottom: var(--spacing--xl);
}

.CampaignTeaser_featured {
	padding-bottom: var(--spacing--2xl);
	background-color: var(--color-action--1);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0) var(--spacing--2xl),
		var(--color-action--1) var(--spacing--2xl)
	);
	color: var(--color-white);

	.CampaignTeaser___light & {
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) var(--spacing--2xl),
			var(--color-light--3) var(--spacing--2xl)
		);
		color: var(--color-black);
	}
}

.CampaignTeaser_featuredInner {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--xl);

	@include mq("md") {
		flex-direction: row;
	}
}

.CampaignTeaser_featuredItem {
	@include mq("md") {
		flex: 1 1 50%;
		width: calc(50% - var(--spacing--xl) / 2); // Fallback for Safari
	}
}

.CampaignTeaser_featuredImageContainer {
	margin-bottom: var(--spacing--md);
}

.CampaignTeaser_featuredImage {
	aspect-ratio: 16 / 10;
}

.CampaignTeaser_featuredHeading {
	margin-bottom: var(--spacing--xs);
}

.CampaignTeaser_featuredText {
	margin: 0;
}

.CampaignTeaser_featuredLink {
	margin-top: var(--spacing--lg);
}

.CampaignTeaser_slides {
	background: linear-gradient(
		180deg,
		var(--color-action--1) 0%,
		var(--color-action--1) var(--spacing--xl),
		rgba(0, 0, 0, 0) var(--spacing--xl)
	);

	.CampaignTeaser___light & {
		background: linear-gradient(
			180deg,
			var(--color-light--3) 0%,
			var(--color-light--3) var(--spacing--xl),
			rgba(0, 0, 0, 0) var(--spacing--xl)
		);
	}
}

.CampaignTeaser .CampaignTeaser_slides {
	:global(.swiper-slide-next) {
		background-color: var(--color-white);
		opacity: 1;

		> * {
			opacity: 0.5;

			@include mq("md") {
				opacity: 1;
			}
		}
	}
}
