@import "ui/styles/2-tools/tools.media-query.scss";

.SearchResultList {
	padding-bottom: calc(var(--spacing--4xl) * 2);

	@include mq("md") {
		padding-bottom: var(--spacing--5xl);
	}

	&_grid {
		gap: var(--spacing--lg);
	}
}
