@use "sass:math";

/* ------------------------------------ *\
		tools.aspect-ratio
\* ------------------------------------ */

@mixin aspect-ratio($width, $height) {
	position: relative;
	width: 100%;
	display: block;
	overflow: hidden;
	padding-top: #{math.div($height, $width) * 100%};

	& > * {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}
}

@mixin circle($size) {
	@if not $size {
		@error "Property #{$size} does not exist";
	}
	width: $size;
	height: $size;
	border-radius: 50%;
	overflow: hidden;
}
