@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.SearchResultItem {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--xs);
	color: currentColor;
	text-decoration: none;

	&:focus {
		.SliderNewsItem_heading {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				top: calc(var(--border--sm) * -1);
				left: 0;
				right: 0;
				border-top: var(--border--2xl) solid var(--color-focus-outline);
			}
		}
	}

	@include HoverFocus {
		.SearchResultItem_heading {
			text-decoration: underline;
		}
	}

	&_date {
		color: var(--color-darkGrey);
	}

	&_wrapper {
		display: flex;
		flex-direction: column;
		gap: var(--spacing--3xs);
		align-items: flex-start;
	}

	&_text {
		margin-bottom: 0;

		@include mq("md") {
			@include lineclamp(3);
		}
	}

	&_link {
		display: inline-block;
		padding-right: var(--spacing--2xs);
		font-size: 1.3rem;
		line-height: var(--line-height--2xl);
		font-weight: var(--font-weight--semibold);
		color: var(--color-action--1);
	}

	&_tags {
		display: flex;
		flex-wrap: wrap;
		gap: var(--spacing--4xs);
	}
}
