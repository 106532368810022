@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Tag,
a.Tag {
	// Browser reset
	appearance: none;
	padding: 0;
	border: 0;
	background-color: transparent;

	@include tag;

	position: relative;
	display: inline-flex;

	+ .Tag {
		margin-left: var(--spacing--5xs);
	}

	&___isActive {
		background-color: var(--color-action--1);
	}
}

a.Tag {
	@include tag-link;
}

button.Tag {
	cursor: pointer;
}

.Tag .Tag_tooltip {
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
}
