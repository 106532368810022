@import "ui/styles/2-tools/tools.media-query.scss";

.TagList {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: var(--spacing--5xs);
	padding: 0;

	&___row {
		@include mq("md") {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}
}

.TagList_item {
	list-style: none;
}
