@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Tooltip {
	position: relative;
	display: inline-block;

	&___isActive {
		.Tooltip_content {
			display: block;
		}
	}

	&___isFadingOut {
		.Tooltip_content {
			opacity: 0;
		}
	}
}

.Tooltip_content {
	display: none;
	position: absolute;
	bottom: calc(100% + 0.6rem);
	left: 50%;
	width: max-content;
	max-width: 20rem;
	border-radius: var(--corner-size--sm);
	padding: var(--spacing--3xs);
	z-index: 50;
	font-size: var(--font-size--xs);
	font-weight: var(--font-weight--normal);
	line-height: var(--line-height--lg);
	color: var(--color-white);
	background-color: rgba(0, 0, 0, 0.8);
	transform: translateX(-50%);
	transition: opacity var(--trans-time--longer) ease-in;

	&:before {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		width: 0;
		height: 0;
		border-width: 0.5rem;
		border-style: solid;
		border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
		transform: translateX(-50%);
	}
}
