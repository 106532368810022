@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.media-query.scss";

.SearchBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: var(--color-light--2);

	&_closeButtonWrapper {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		margin: 0;
		padding-top: var(--spacing--sm);
		padding-bottom: var(--spacing--sm);
	}

	&_closeButton {
		// Browser reset
		border: 0;
		padding: 0;
		background: transparent;
		@include action;
	}

	&_content {
		width: 100%;
		padding: 0 0 var(--spacing--2xl);

		@include mq("lg") {
			padding: var(--spacing--2xl) 0;
		}
	}

	&_form {
		max-width: 120rem;
	}
}
