@import "ui/styles/2-tools/tools.media-query.scss";

.QuoteModule {
	margin: var(--spacing--xl) 0;
	padding: var(--spacing--lg) var(--spacing--md);
	background-color: var(--color-light--3);
	text-align: center;

	@include mq("md") {
		margin: var(--spacing--3xl) 0;
		padding: var(--spacing--3xl) calc(var(--spacing--4xl) + 1rem);
	}

	&___small {
		margin: var(--spacing--md) 0;
		padding: var(--spacing--sm) 0;
		background-color: var(--color-white);
		text-align: left;

		@include mq("md") {
			margin: var(--spacing--xl);
			padding: var(--spacing--xl);
		}

		.QuoteModule_heading {
			font-style: normal;
			font-family: var(--font-secondary);
			font-weight: var(--font-weight--medium);
			font-size: var(--font-size--lg);
		}

		.QuoteModule_text {
			text-transform: none;
			font-weight: var(--font-weight--normal);
			letter-spacing: 0em;
		}

		.QuoteModule_border {
			border-left: 4px solid var(--color-action--1);
			padding: 0 var(--spacing--lg);
		}
	}

	&_text {
		text-transform: uppercase;
		font-weight: var(--font-weight--semibold);
		margin-bottom: 0;
		font-size: var(--font-size--md);
		letter-spacing: 0.12em;
	}

	&_heading {
		margin-bottom: var(--spacing--md);
		color: var(--color-action--1);
		font-style: italic;
		font-size: var(--font-size--xl);
		font-weight: var(--font-weight--medium);
		line-height: var(--line-height--2xl);

		@include mq("md") {
			margin-bottom: var(--spacing--md);
		}
	}
}
