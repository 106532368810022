@import "ui/styles/2-tools/tools.media-query.scss";

.LinkBars_header {
	margin-bottom: var(--spacing--md);

	@include mq("md") {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: var(--spacing--xl);
	}
}

.LinkBars_links {
	@include mq("md") {
		display: flex;
		flex-wrap: wrap;
		column-gap: var(--spacing--lg);
	}
}

.LinkBars_link {
	list-style: none;

	@include mq("md") {
		flex: 0 0 calc(50% - var(--spacing--lg) / 2);
	}
}

.LinkBars_button {
	&___mobile {
		display: inline-block;
		margin-top: var(--spacing--2xl);

		@include mq("md") {
			display: none;
		}
	}

	&___desktop {
		display: none;

		@include mq("md") {
			display: block;
		}
	}
}
