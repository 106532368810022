@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.mixins.scss";
@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";

.CludoSearch {
	:global(.search_autocomplete) {
		margin-top: calc(var(--spacing--3xs) * -1);
		border: var(--border--md) solid var(--color-action--1);
		border-radius: 1rem;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-top: 0;
		background-color: var(--color-white);

		li {
			padding: var(--spacing--4xs) var(--spacing--xs);
			list-style: none;
			cursor: pointer;

			@include HoverFocus {
				color: var(--color-white);
				background-color: var(--color-action--1);
			}

			&:first-child {
				border-top: var(--border--sm) solid var(--color-light--1Grey);
			}
		}
	}

	&_filter {
		flex: 1 1 100%;

		@include mq("md") {
			position: relative;
			min-width: 30rem;
			max-width: 30rem;
			flex-basis: 30rem;
			height: 6rem;
		}
	}

	&_resultsList {
		display: none;
		padding-bottom: 0;

		&___isActive {
			display: block;
		}
	}

	&_results {
		:global(.cludo-search-results-list__list) {
			display: flex;
			flex-direction: column;
			row-gap: var(--spacing--lg);
			padding: 0;
			padding-bottom: calc(var(--spacing--4xl) * 2);

			@include mq("md") {
				padding-bottom: var(--spacing--5xl);
			}

			li {
				list-style: none;
			}

			a {
				display: flex;
				flex-direction: column;
				color: inherit;
				text-decoration: none;

				&:focus-visible {
					@include focusBoxShadow("", true);
				}

				@include HoverFocus {
					h2 {
						text-decoration: underline;
					}
				}
			}
		}

		h2 {
			margin-bottom: var(--spacing--5xs);
			font-family: var(--font-primary);
			font-weight: var(--font-weight--semibold);
			font-size: var(--heading-font-size--2xs);
			line-height: var(--line-height--xl);
		}

		p {
			@include paragraph;

			margin-bottom: 0;

			@include mq("md") {
				@include lineclamp(3);
			}
		}

		:global(.cludo-search-results-item__date) {
			display: block;
			margin-bottom: var(--spacing--xs);
			font-family: var(--font-secondary);
			font-size: var(--font-size--sm);
			line-height: var(--line-height--xl);
			font-weight: var(--font-weight--normal);
			color: var(--color-darkGrey);
		}

		:global(.cludo-search-results-item__breadcrumb) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			row-gap: 0.3rem;
			margin-top: var(--spacing--3xs);
			color: var(--color-action--1);
			font-family: var(--font-secondary);
			font-size: 1.3rem;
		}

		:global(.cludo-search-results-item__breadcrumb-item) {
			display: flex;
			align-items: center;

			&:not(:last-child) {
				&:after {
					content: "";
					width: 0.4rem;
					height: 0.4rem;
					margin: 0 var(--spacing--2xs);
					border-right: 0.1rem solid var(--color-action--1);
					border-top: 0.1rem solid var(--color-action--1);
					transform: rotate(45deg) scale(1.5) translate(-10%, 30%); // We use scale() to make the border thicker because it's not possible to reach the desired thickness with rem/px units
				}
			}

			&:last-child {
				font-weight: var(--font-weight--semibold);
			}
		}
	}
}

// Pagination
.CludoSearch :global(.cludo-page-navigation) {
	padding: var(--spacing--md) var(--spacing--sm);
	border-top: var(--border--sm) solid var(--color-mediumGrey);

	:global(.search_page_list) {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: var(--spacing--xs) var(--spacing--3xs);

		@include mq("sm") {
			gap: var(--spacing--lg);
			justify-content: center;
		}

		@include mq("lg") {
			gap: var(--spacing--xl);
		}

		li,
		li a {
			@include formfield-label;

			display: flex;
			justify-content: center;
			align-items: center;
			height: var(--spacing--md);
			width: var(--spacing--md);
			outline: none;
			border-radius: 50%;
		}

		li {
			background-color: var(--color-white);
			color: var(--color-action--1);
			font-weight: var(--font-weight--semibold);
			box-shadow: 0px 0px 0px var(--border--sm) var(--color-action--1);

			@include transition {
				transition: all var(--trans-time--shorter);
			}

			&:hover:not(.active),
			&:focus:not(.active) {
				font-weight: var(--font-weight--bold);
				box-shadow: 0px 0px 0px var(--border--md) var(--color-action--1);
			}

			&:global(.active) {
				background-color: var(--color-action--1);
				color: var(--color-white);
				cursor: default;

				&:hover,
				&:focus {
					color: var(--color-white);
				}
			}

			&:global(.previous),
			&:global(.next) {
				position: relative;

				a {
					opacity: 0;
				}

				&:after {
					content: "";
					display: block;
					position: absolute;
					width: calc(var(--sizes-checkbox-height) / 2);
					height: calc(var(--sizes-checkbox-height) / 2);
					border: solid var(--color-action--1);
					border-width: 0 var(--border--md) var(--border--md) 0;
					transform-origin: center;
				}
			}

			&:global(.previous) {
				&:after {
					transform: rotate(135deg) translate(-15%, -15%);
				}
			}

			&:global(.next) {
				&:after {
					transform: rotate(-45deg) translate(-15%, -15%);
				}
			}
		}

		li a {
			text-decoration: none;

			&:focus-visible {
				outline: var(--border--lg) solid var(--color-focus-outline);
				outline-offset: var(--spacing--3xs);
			}
		}
	}

	:global(.cludo-sr-only) {
		@include visually-hidden;
	}
}
