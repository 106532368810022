@import "ui/styles/2-tools/tools.media-query.scss";

.FooterCard_heading {
	margin-bottom: var(--spacing--sm);
	font-family: var(--font-primary);
	font-size: var(--footercard-font-size-heading);
	color: var(--color-dark--4);
	text-transform: uppercase;
	letter-spacing: 0.15em;
}

.FooterCard___theme-light .FooterCard_heading {
	color: var(--color-action--1);
}

.FooterCard_content {
	font-size: var(--footercard-font-size-body);

	p:not(:last-child) {
		margin-bottom: var(--spacing--sm);
	}

	a {
		color: currentColor;

		&[href^="tel:"] {
			text-decoration: none;
		}
	}
}
