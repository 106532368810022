@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Paragraph {
	@include paragraph;
}

.Paragraph___lg {
	@include paragraph--lg;
}

.Paragraph___strong {
	@include paragraph--strong;
}
