@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.FormfieldCombobox {
	&_fieldContainer {
		@include mq("md") {
			height: var(--sizes-input-height);
		}
	}

	&_field {
		--formfield-borderradius: 1rem;
		position: relative;
		border: var(--border--md) solid transparent;
		border-radius: var(--formfield-borderradius);
		background-color: var(--color-white);

		@include transition {
			transition: all var(--trans-time--shorter);
		}

		@include HoverFocus {
			border-color: var(--color-action--1);

			.FormfieldCombobox_toggle {
				border: 0;
			}
		}

		&___hasSelected {
			border-color: var(--color-action--2);
		}

		&___open {
			border-color: var(--color-action--1);
			z-index: 1;
		}
	}

	&_toggle {
		@include input;
		-webkit-appearance: none;
		width: 100%;
		text-align: left;
		padding-right: calc(var(--spacing--xs) * 2 + var(--sizes-checkbox-height));
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		&:after {
			content: "";
			display: block;
			width: calc(var(--sizes-checkbox-height) / 2);
			height: calc(var(--sizes-checkbox-height) / 2);
			transform: rotate(45deg) translate(130%, -170%);
			border: solid var(--color-black);
			border-width: 0 var(--border--md) var(--border--md) 0;
			flex-shrink: 0;
		}

		&[aria-expanded="true"] {
			border: 0;

			&:after {
				transform: rotate(225deg) translate(-170%, 130%);
			}
		}

		span {
			@include ellipsis;
		}
	}

	&_formfieldgroup {
		border-top: var(--border--sm) solid var(--color-light--1Grey);
		width: 100%;
		max-height: 23rem;
		overflow-y: auto;
		border-radius: 0 0 var(--formfield-borderradius) var(--formfield-borderradius);
		position: relative;
		background-color: var(--color-white);

		&::-webkit-scrollbar {
			width: 1rem;
		}

		&::-webkit-scrollbar-track {
			border-radius: var(--formfield-borderradius);
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: var(--formfield-borderradius);
			background-color: var(--color-light--1Grey);

			&:hover,
			&:focus {
				background-color: var(--color-mediumGrey);
			}
		}

		&.FormfieldCombobox_formfieldgroup___closed // Extra specificity to override FormfieldGroup styles
		{
			display: none;
		}
	}

	&_label {
		margin-bottom: var(--spacing--3xs);
		cursor: default;
		&:hover,
		&:focus {
			color: currentColor;
		}
	}

	&_errorMessage {
		color: var(--color-error);
	}
}
