@import "ui/styles/2-tools/tools.media-query.scss";

.Banner {
	--image-offset-top: var(--spacing--2xl);
	--image-offset-side: 0;

	padding-top: var(--image-offset-top);
	padding-left: var(--image-offset-side);

	@include mq("md") {
		--image-offset-top: var(--spacing--xl);
		--image-offset-side: var(--spacing--2xl);
	}

	@include mq("lg") {
		--image-offset-top: calc(var(--spacing--3xl) + 1rem);
		--image-offset-side: calc(var(--spacing--4xl) + 1rem);
	}

	&___dark {
		.Banner_inner {
			background-color: var(--color-action--1);

			.Banner_content {
				color: var(--color-white);

				.Banner_text {
					a {
						color: var(--color-white);
					}
				}
			}

			.LinkButton___primary-negative {
				color: var(--color-action--1);
			}
		}
	}

	&_inner {
		display: flex;
		flex-direction: column;
		background-color: var(--color-light--3);

		@include mq("md") {
			flex-direction: row;
			align-items: center;
			column-gap: var(--spacing--xl);
			padding-right: var(--spacing--xl);
		}

		@include mq("lg") {
			column-gap: calc(var(--spacing--3xl) + 1rem);
			padding-right: var(--image-offset-side);
		}
	}

	&_imageContainer {
		align-self: flex-start;
		width: 70%;
		margin-top: calc(var(--image-offset-top) * -1);
		margin-left: calc(var(--image-offset-side) * -1);
		aspect-ratio: 1.2 / 1;

		@include mq("md") {
			flex: 0 0 50%;
			width: 50%;
			max-width: 57rem;
			margin-bottom: var(--spacing--3xl);
			aspect-ratio: 57 / 50;
		}

		@include mq("lg") {
			flex: 0 0 100%;
			width: 100%;
			margin-bottom: calc(var(--spacing--5xl) - 0.5rem);
		}
	}

	&_content {
		flex: 1 1 auto;
		max-width: 52rem;
		padding: var(--spacing--md) var(--spacing--sm) var(--spacing--2xl);

		@include mq("md") {
			padding: var(--spacing--xl) 0;
		}

		@include mq("lg") {
			padding: var(--spacing--3xl) 0;
		}
	}

	&_heading {
		margin-bottom: var(--spacing--xs);
	}

	&_text {
		@include mq("md") {
			width: 80%;
		}
	}

	&_button {
		margin-top: var(--spacing--lg);
	}
}

// Right aligned
.Banner___image-right {
	padding-left: 0;
	padding-right: var(--image-offset-side);

	.Banner_inner {
		@include mq("md") {
			flex-direction: row-reverse;
			padding-left: var(--spacing--xl);
			padding-right: 0;
		}

		@include mq("lg") {
			padding-left: var(--image-offset-side);
		}
	}

	.Banner_imageContainer {
		align-self: flex-end;
		margin-left: 0;
		margin-right: calc(var(--image-offset-side) * -1);

		@include mq("md") {
			align-self: flex-start;
		}
	}

	.Banner_content {
		margin-right: auto;
	}
}
