.DesktopNavigation {
	text-align: center;
	position: relative;
	z-index: 10;
	margin-top: var(--spacing--sm);
}

.DesktopNavigation_list {
	list-style: none;
	display: flex;
	justify-content: center;
}
