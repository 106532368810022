@import "ui/styles/2-tools/tools.media-query.scss";

.LinkBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: var(--spacing--md);
	padding-bottom: var(--spacing--md);
	padding-right: var(--spacing--4xs);
	border-bottom: 1px solid var(--color-mediumGrey);
	font-size: var(--linkbar-font-size);
	text-decoration: none;

	@include mq("md") {
		padding-top: var(--spacing--lg);
		padding-bottom: var(--spacing--lg);
		padding-right: var(--spacing--lg);
	}

	&:hover,
	&:focus,
	&:active {
		text-decoration: underline;

		.LinkBar_icon {
			transform: translateX(var(--spacing--4xs));
		}
	}

	&_icon {
		flex: 0 0 auto;
		margin-left: var(--spacing--sm);
		width: unset;
		height: unset;
		color: var(--color-action--1);
		transition: transform var(--trans-time--standard) var(--ease--dynamic);

		svg {
			fill: none;
		}
	}
}

a.LinkBar {
	color: currentColor;
}
