@import "ui/styles/2-tools/tools.media-query.scss";

.NewsSubscriptionReceipt {
	&_recipient,
	&_topics {
		padding: var(--spacing--sm);
		background-color: var(--color-light--3);
	}

	&_recipient {
		margin-bottom: var(--border--md);

		&Info {
			margin: 0;
		}
	}

	&_topics {
		display: flex;
		flex-direction: column;
		row-gap: var(--spacing--sm);
		padding-bottom: var(--spacing--md);

		@include mq("md") {
			flex-direction: row;
			padding-bottom: var(--spacing--xl);
		}
	}

	&_topicGroup {
		@include mq("md") {
			flex: 1 1 50%;
			width: 50%;
			padding-right: var(--spacing--md);
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li:not(:last-child) {
				margin-bottom: var(--spacing--5xs);
			}
		}

		&_heading {
			margin-bottom: var(--spacing--2xs);
			font-size: inherit;
			line-height: inherit;
		}
	}
}
