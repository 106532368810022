@import "ui/styles/2-tools/tools.media-query.scss";

.Breadcrumb {
	position: relative;
	background-color: var(--breadcrumb-bg-dark);

	&___theme-light {
		background-color: var(--breadcrumb-bg-light);
	}

	&_outer {
		&:before {
			@include mq("md") {
				content: "";
				position: relative;
				display: block;
				top: 0;
				height: 1px;
				width: calc(100% - (var(--spacing--base) * 2));
				left: var(--spacing--base);
				right: var(--spacing--base);
				background-color: var(--color-dark--3);
			}
		}
	}

	&_items {
		display: flex;
		flex-wrap: wrap;
		column-gap: var(--spacing--2xs);
		align-items: center;
		padding: var(--spacing--3xs) 0;

		@include mq("md") {
			padding: var(--spacing--sm) 0;
		}
	}

	&_item {
		margin: 0;
		list-style: none;

		// Breadcrumb divider
		&:not(:last-child)::after {
			display: inline-block;
			content: "";
			vertical-align: middle;
			margin: 0 0.1rem;
			width: var(--icon-size--xs);
			height: var(--icon-size--xs);
			border-color: var(--color-action--2);
			border-style: solid;
			border-width: var(--border--sm) var(--border--sm) 0 0;
			transform: rotate(45deg);
		}
	}

	&___theme-light &_item {
		color: var(--color-black);

		&:not(:last-child)::after {
			border-color: var(--color-black);
		}
	}

	&_url {
		display: inline-block;
		padding-right: var(--spacing--2xs);
		font-size: 1.3rem;
		line-height: var(--line-height--2xl);
		font-weight: var(--font-weight--normal);
		color: var(--color-action--2);
		text-decoration: none;

		&:hover,
		&:focus {
			color: var(--color-dark--4);
		}
	}

	&___theme-light &_url {
		color: var(--color-black);
	}

	&_item:last-child &_url {
		padding-right: 0;
		font-weight: var(--font-weight--semibold);
	}
}
