@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.DocumentList {
	text-align: center;
	margin-top: var(--spacing--xl);
	background-color: var(--color-light--3);

	&___desktop {
		display: none;

		@include mq("md") {
			display: block;
		}
	}

	&___mobile {
		display: block;

		@include mq("md") {
			display: none;
		}
	}
}

.DocumentList_teaser {
	@include mq("md") {
		padding: 0 var(--spacing--4xl) var(--spacing--4xl) var(--spacing--4xl);
	}
}

.DocumentList_Linkbox {
	background-color: #f0f5f3;
	margin-bottom: var(--spacing--4xl);
	padding: var(--spacing--4xl) var(--spacing--3xl);

	ul {
		display: block;
	}

	@include mq("md") {
		padding: var(--spacing--3xl);
	}

	@include mq("lg") {
		padding: var(--spacing--4xl);
		margin-bottom: 0;
	}
}
