@import "ui/styles/2-tools/tools.media-query.scss";

.JobList {
	&_top_header {
		border-bottom: var(--border--sm) solid var(--color-mediumGrey);
		width: 100%;
		padding: var(--spacing--lg) 0;
		margin-bottom: var(--spacing--xl);
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		gap: var(--spacing--sm);

		@include mq("md") {
			margin-bottom: var(--spacing--3xl);
		}
	}

	&_side__left {
		display: flex;
		align-items: center;
	}

	&_side__right {
		display: flex;
		align-items: center;
	}

	&_grid {
		gap: var(--spacing--md);
		flex-direction: row;
	}

	// Something about the order of .scss-files are imported forces me to increase the specificity.
	// Otherwise all the classes from GridCell will win over this or I have to use !important instead.
	&_cell[class*="GridCell"] {
		@include mq("md") {
			max-width: calc(50% - var(--spacing--xs));
		}
	}
}
