@import "ui/styles/2-tools/tools.typography-mixins.scss";

.CampaignTeaserItem {
	display: flex;
	flex-direction: column;
	height: 100%;
	color: currentColor;
	text-decoration: none;

	&:focus {
		padding-bottom: 1rem;
		border-bottom: 0.5rem solid var(--color-action--1);
	}

	&:focus,
	&:active,
	&:hover {
		text-decoration: none;

		.CampaignTeaserItem_link {
			text-decoration: underline;
		}
	}
}

.CampaignTeaserItem_imageContainer {
	margin-bottom: var(--spacing--sm);
}

.CampaignTeaserItem_image {
	aspect-ratio: 15 / 10;
	height: auto;
}

.CampaignTeaserItem_heading {
	margin-bottom: var(--spacing--xs);
}

.CampaignTeaserItem_link {
	margin-top: auto;
	color: var(--color-action--1);
}
