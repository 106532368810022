@import "ui/styles/2-tools/tools.media-query.scss";

.NewsSubscriptionForm {
	&_fields {
		display: flex;
		flex-direction: column;
		row-gap: var(--spacing--base);
		margin-bottom: var(--spacing--lg);

		@include mq("md") {
			flex-flow: row wrap;
			gap: var(--spacing--lg) var(--spacing--md);
			margin-bottom: var(--spacing--3xl);
		}
	}

	&_field {
		@include mq("md") {
			width: calc(50% - 0.5 * var(--spacing--md));
		}
	}

	&_action {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: var(--spacing--sm);
	}

	&_submit {
		display: flex;
		align-items: center;
		column-gap: var(--spacing--2xs);
	}

	&_submitError {
		margin: var(--spacing--xs) 0;
		color: var(--color-error);
	}
}
