@import "ui/styles/2-tools/tools.display-mixins";

.Logo {
	display: inline-block;
	text-decoration: none;
	color: currentColor;

	// &___light {
	// Currently not in use
	// }
}

.Logo_heading {
	@include hidden-absolute;
}

.Logo_asset {
	display: block;
	width: 24rem;
	max-width: 100%;

	&___sm {
		width: 21rem;
	}
}
