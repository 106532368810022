@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.Pagination {
	list-style: none;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
	gap: var(--spacing--4xs);

	&_wrapper {
		padding: var(--spacing--md) var(--spacing--sm);
		border-top: var(--border--sm) solid var(--color-mediumGrey);
	}

	@include mq("sm") {
		gap: var(--spacing--lg);
		justify-content: center;
	}

	@include mq("lg") {
		gap: var(--spacing--xl);
	}

	&_item,
	&_prev,
	&_next {
		height: var(--spacing--md);
		width: var(--spacing--md);

		&___disabled {
			display: none;
		}
	}

	&_break {
		height: var(--spacing--sm);
		width: var(--spacing--sm);
	}

	&_prevLink,
	&_nextLink,
	&_link {
		box-shadow: 0px 0px 0px var(--border--sm) var(--color-action--1);
	}

	&_link,
	&_breakLink {
		@include formfield-label;
		font-weight: var(--font-weight--semibold);
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		outline: none;
		text-decoration: none;
		border-radius: 50%;
		background-color: var(--color-white);
		color: var(--color-action--1);
		@include transition {
			transition: all var(--trans-time--shorter);
		}

		&:focus-visible {
			outline: var(--border--lg) solid var(--color-focus-outline);
			outline-offset: var(--spacing--3xs);
		}

		&:hover:not(&___active),
		&:focus:not(&___active) {
			font-weight: var(--font-weight--bold);
			box-shadow: 0px 0px 0px var(--border--md) var(--color-action--1);
		}

		&___active {
			background-color: var(--color-action--1);
			color: var(--color-white);
			cursor: default;

			&:hover,
			&:focus {
				color: var(--color-white);
			}
		}
	}

	&_prevLink,
	&_nextLink {
		display: block;

		&:disabled {
			display: none;
		}
		&:after {
			content: "";
			display: block;
			width: calc(var(--sizes-checkbox-height) / 2);
			height: calc(var(--sizes-checkbox-height) / 2);
			border: solid var(--color-action--1);
			border-width: 0 var(--border--md) var(--border--md) 0;
		}
	}

	&_prevLink {
		&:after {
			transform: rotate(135deg) translate(0%, -160%);
		}
	}

	&_nextLink {
		&:after {
			transform: rotate(-45deg) translate(-10%, 140%);
		}
	}
}
