@import "ui/styles/2-tools/tools.media-query.scss";

.Footer {
	font-family: var(--font-secondary);
	padding: calc(#{var(--spacing--md)} * 2) 0;
	line-height: var(--line-height--2xl);
	color: var(--color-white);
	background-color: var(--color-dark--1);

	&___theme-light {
		color: var(--color-black);
		background-color: var(--color-white);
	}
}

.Footer_grid {
	row-gap: var(--spacing--3xl);
}

.Footer_logoContainer {
	@include mq("md") {
		padding-right: var(--spacing--lg);
	}
}

.Footer_cards {
	row-gap: var(--spacing--2xl);
}

.Footer_bottomNav {
	border-top: 1px solid var(--color-dark--3);
	margin-top: var(--spacing--4xl);
	padding-top: var(--spacing--xl);

	@include mq("md") {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&_links {
		a {
			color: currentColor;
		}
	}
}

.Footer___theme-light .Footer_bottomNav {
	border-top-color: var(--color-darkGrey);
}
